<template>
  <div class="thanks">
    <div class="container">
      <div class="title">
        <p class="text-sm-h2 text-h5 text-center mb-10">СПАСИБО ЗА ЗАЯВКУ!</p>
        <p class="text-center">
          НАШ ЮРИСТ ПРОАНАЛИЗИРУЕТ ВАШЕ ОБРАЩЕНИЕ И ПОМОЖЕТ ВЕРНУТЬ ПРАВА!<br/>
          МЫ СВЯЖЕМСЯ С ВАМИ ОЧЕНЬ ОПЕРАТИВНО!
        </p>

        <div class="messengers">
          <div class="title">
            <p>Выберите удобный способ связи</p>
          </div>
          <div class="button-wrap">
            <v-btn width="130" class="ma-2" color="primary" href="https://wa.me/79250194968?text=Начать"><v-icon>mdi-whatsapp</v-icon> WhatsApp</v-btn>
            <v-btn width="130" class="ma-2" color="primary" href="https://t.me/Avtourist_PAU_bot">Telegram</v-btn>
            <v-btn width="130" class="ma-2" color="primary" href="viber://pa?chatURI=avtourist-bot&context=welcome/">Viber</v-btn>
            <v-btn width="130" class="ma-2" color="primary" href="https://vk.com/write-7375007">VK</v-btn>
          </div>
        </div>
        <p class="mb-10 text-center">
          <v-btn
            v-if="1===2"
            rounded
            color="yellow"
            class="black--text mt-10"
            style="font-size: 20px"
            width="250px"
            height="50px"
            @click="$router.push({ name: 'Home' })"
          >
            На главную
          </v-btn>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Thanks',
  data () {
    return {
      form: {
        name: null,
        email: null,
        city: null,
        phone: null
      },
      nameRules: [
        v => !!v || 'Введите город'
      ],
      phoneRules: [
        value => !!value || 'Обязательное поле',
        value => (Boolean(Number(value))) || 'Введите только цифры'
      ],
      emailRules: [
        v => !!v || 'E-mail обязательное поле',
        v => /.+@.+/.test(v) || 'E-mail введен не правильно'
      ],
      validForm: false
    }
  },
  methods: {
    send (val) {
      if (this.form.phone) {
        const formData = new FormData()
        formData.append('crm', '11')
        formData.append('pipe', '22')
        formData.append('contact[199]', this.form.phone)
        formData.append('contact[200]', this.form.email)
        formData.append('lead[214]', 'dtp.avtourist.info')
        formData.append('note', 'сайт: dtp.avtourist.info')
        axios.post('https://urist.v-avtoservice.com/api/import-lead',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
      }
    },
    reloadPage () {
      document.location.reload(true)
      localStorage.reloadPage = false
    }
  },
  mounted () {
    if (localStorage.reloadPage) return false
    else localStorage.reloadPage = true
    document.addEventListener('DOMContentLoaded', localStorage.reloadPage && this.reloadPage())
  }
}
</script>

<style scoped lang="scss">
html {overflow: auto}
.messengers {
  max-width: 40%;
  margin: 0 auto;
  .title {
    margin-top: 30px!important;
    text-align: center;
  }
  @media (max-width: 1200px) {
    max-width: 80%;
  }
  .button-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.thanks {
  .title {
    margin-top: calc(50vh - 250px);
    &:last-child p {
      @media (max-width: 600px) {
        font-size: 14px;
      }
    }
  }
}
</style>
